import { LitElement, html, css } from 'lit';

class TrackingBanner extends LitElement {
  static properties = {
    imageUrl: { type: String, reflect: true },
    url: { type: String, reflect: true },
    trackingName: { type: String, reflect: true },
    maxWidth: { type: String, reflect: true },
    width: { type: String, reflect: true },
  }
  static styles = css`
    .banner-image img {
      display: block;
    }
  `
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.width = '100%';
  }
  clickTracking() {
    const trackingName = `click_${this.trackingName}`
    console.log(trackingName)
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: trackingName,
    })
  }
  displayTracking() {
    const trackingName = `display_${this.trackingName}`
    console.log(trackingName)
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: trackingName,
    })
  }
  imageLoad() {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) return;
      this.displayTracking();
    });
    const target = this.shadowRoot.querySelector('.banner-image')
    intersectionObserver.observe(target);
  }
  render() {
    return html`
      <div class="banner-image">
        <a href="${this.url}" @click="${this.clickTracking}" target="_blank" rel="noopener noreferrer">
          <img src="${this.imageUrl}" alt="banner" @load="${this.imageLoad}" style="max-width: ${this.maxWidth}; width: ${this.width};">
        </a>
      </div>
    `;
  }
}
if(!customElements.get('tracking-banner'))
  customElements.define('tracking-banner', TrackingBanner);